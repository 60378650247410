import { ComponentPropsWithoutRef } from "react";
import styled from "styled-components/macro";

export type Props = ComponentPropsWithoutRef<"button">;

/**
 * A button with all the button styles removed.
 * Useful when an element should be a 'button' tag despite not looking like a button.
 */
const BlankButton = styled("button")<Props>`
  display: flex;
  flex-direction: row;
  text-align: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  border: none;
  background-color: inherit;
  color: inherit;
  height: auto;
  width: auto;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  font-size: 1rem;
  font-family: Montserrat;
`;

export default BlankButton;
