import { useRef, useEffect, useCallback } from "react";

export default function useFocus<T extends HTMLElement>(automatic?: boolean) {
  const focusRef = useRef<T | null>(null);

  const focus = useCallback(() => {
    if (focusRef.current) {
      focusRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (automatic) {
      focus();
    }
  }, [automatic, focus]);

  return {
    focusRef,
    focus,
  };
}
