export const wideMin = "(min-width: 1440px)"; // wide only
export const desktopMax = "(max-width: 1439px)"; // desktop, tablet, mobile
export const desktopMin = "(min-width: 1024px)"; // wide, desktop
export const tabletMax = "(max-width: 1023px)"; // tablet, mobile
export const tabletMin = "(min-width: 769px)"; // wide, desktop, tablet
export const mobileMax = "(max-width: 768px)"; // mobile only
export const mobileMaxRotated = "(max-height: 768px)";

export const wide = wideMin; // wide only
export const desktop = `${desktopMax} and ${desktopMin}`; // desktop only
export const tablet = `${tabletMax} and ${tabletMin}`; // tablet only
export const mobile = mobileMax; // mobile only

export const shortScreen = `(max-height: 530px)`;
