import { useCallback, useMemo, useState } from "react";
import { useIdleTimer, EventsType } from "react-idle-timer";
import { useQuery } from "@tanstack/react-query";
import { MINUTE } from "shared/lib/constants/timeConstants";
import getSession from "../api/auth/getSession";
import logout from "../api/auth/logout";
import { useWorkerTimeout } from "./useWorkerTimeout";
import { refreshSession } from "../api/auth/refreshSession";
import { useWorkerInterval } from "./useWorkerInterval";

const SESSION_REFRESH_INTERVAL = 30 * MINUTE;
const INACTIVITY_WARNING_THRESHOLD_MAX = 20 * MINUTE;
const INACTIVITY_WARNING_THRESHOLD_MIN = MINUTE;

function calculateIdleWarningThreshold(expirationMillis: number) {
  // This is mainly during QA testing, pop the warning when half the time is left
  if (expirationMillis < 20 * MINUTE) {
    return Math.max(
      INACTIVITY_WARNING_THRESHOLD_MIN,
      expirationMillis - expirationMillis / 2,
    );
  }
  return Math.max(
    INACTIVITY_WARNING_THRESHOLD_MIN,
    expirationMillis - INACTIVITY_WARNING_THRESHOLD_MAX,
  );
}

const IDLE_EVENTS: EventsType[] = [
  "keydown",
  "wheel",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "MSPointerDown",
  "MSPointerMove",
];

export function useSession() {
  const [idle, setIdle] = useState(false);
  const {
    data: { session, expiresAt } = { session: null, expiresAt: null },
    refetch,
    isLoading,
    remove,
  } = useQuery({
    queryKey: [getSession.queryKey],
    queryFn: getSession,
  });

  const reloadSession = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const cleanupSession = useCallback(() => {
    remove();
    reloadSession();
  }, [reloadSession, remove]);

  const { expiresIn, idleTimeout } = useMemo(() => {
    // If its already expired, the diff between that time and now will be less than 0
    const expires = expiresAt ? Math.max(0, expiresAt - Date.now()) : 0;
    return {
      expiresIn: expires,
      idleTimeout: calculateIdleWarningThreshold(expires),
    };
  }, [expiresAt]);

  const autoLogout = useCallback(async () => {
    await logout();
    cleanupSession();
  }, [cleanupSession]);

  const refreshAndReloadSession = useCallback(async () => {
    await refreshSession();
    await refetch();
  }, [refetch]);

  const onIdle = useCallback(() => {
    setIdle(true);
  }, []);

  const onAction = useCallback(() => {
    setIdle(false);
  }, []);

  useIdleTimer({
    timeout: idleTimeout,
    onIdle,
    onAction,
    events: IDLE_EVENTS,
  });

  useWorkerTimeout(autoLogout, expiresIn, expiresAt === null);
  useWorkerInterval(
    refreshAndReloadSession,
    SESSION_REFRESH_INTERVAL,
    idle || !expiresAt || !session,
  );

  return {
    sessionLoading: isLoading,
    session,
    cleanupSession,
    reloadSession,
    idle,
  };
}
