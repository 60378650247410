import Column from "@emberex/components/lib/Column";
import React, {
  FC,
  MouseEventHandler,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";

/**
 * Used to keep track of the number of overlays open that need the body scrollbars hidden.
 * When this number drops to `0`, the body scroll styles are reset to allow scrolling.
 */
let hideBodyScrollbarCount = 0;

export interface Props {
  /**
   * Whether or not to render the overlay directly inside `document.body`. Defaults to `true`.
   */
  inRoot?: boolean;

  onClick?: MouseEventHandler<HTMLDivElement>;
}

const Overlay: FC<PropsWithChildren<Props>> = props => {
  const { inRoot = true, ...rest } = props;
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (inRoot) {
      const scrollingElement = document.scrollingElement || document.body;
      hideBodyScrollbarCount += 1;
      document.body.style.overflowX = "hidden";
      document.body.style.overflowY = "hidden";
      setOffset(scrollingElement.scrollTop);

      return () => {
        hideBodyScrollbarCount = Math.max(0, hideBodyScrollbarCount - 1);
        if (hideBodyScrollbarCount === 0) {
          document.body.style.overflowX = "hidden";
          document.body.style.overflowY = "auto";
        }
      };
    }
    return () => {};
  }, [inRoot]);

  if (inRoot) {
    return ReactDOM.createPortal(
      <Column
        {...rest}
        style={{ top: Math.max(0, offset - 1) }}
        data-overlay
      />,
      document.body,
    );
  }
  return <Column {...rest} data-overlay />;
};

export function isOverlay(target: HTMLElement) {
  return target && target.dataset && target.dataset.overlay;
}

export default styled(Overlay)<Props>`
  left: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;

  ${props => {
    const position = props.inRoot ? "absolute" : "absolute";
    return props.inRoot
      ? css`
          position: ${position};
          width: 100vw;
          height: 100vh;
        `
      : css`
          position: ${position};
          width: 100%;
          height: 100%;
        `;
  }}
`;
