export enum HealthCheckStatus {
  /**
   * The front end was able to successfully connect to the server.
   */
  HEALTHY = "healthy",
  /**
   * The front end failed to connect to the server at least once.
   */
  INTERRUPTED = "interrupted",
  /**
   * The front end failed to connect to the server multiple times.
   */
  INACCESSIBLE = "inaccessible"
}
