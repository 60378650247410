import { useEffect } from "react";
import { setInterval, clearInterval } from "worker-timers";
import noop from "shared/lib/utils/noop";

type IntervalCallback = () => unknown;

/**
 * Hook to create an interval that is a worker instead of a WindowTimer.
 * This is to get around browsers that sleep/throttle intervals when the tab goes out of focus.
 * @param handler a _memoized_ callback to execute on an interval
 * @param delay the delay between calls to the interval.
 * @param disabled whether the interval is disabled/needs to turn on "later". Default: false
 */
export function useWorkerInterval(
  handler: IntervalCallback,
  delay: number,
  disabled = false,
) {
  useEffect(() => {
    if (disabled) {
      return noop;
    }
    const interval = setInterval(handler, delay);
    return () => {
      clearInterval(interval);
    };
  }, [handler, delay, disabled]);
}
