import noop from "shared/lib/utils/noop";
import { useEffect } from "react";
import { setTimeout, clearTimeout } from "worker-timers";

/**
 * Hook to create a timeout that is a worker instead of a WindowTimer.
 * This is to get around browsers that sleep/throttle timeouts when the tab goes out of focus.
 * @param handler a _memoized_ callback to execute on an interval
 * @param timeoutMillis the timeout in milliseconds.
 * @param disabled whether the timeout is disabled/needs to turn on "later". Default: false
 */
export function useWorkerTimeout(
  handler: () => unknown,
  timeoutMillis = 1000,
  disabled = false,
) {
  useEffect(() => {
    if (disabled) {
      return noop;
    }
    const timeout = setTimeout(handler, timeoutMillis);
    return () => {
      clearTimeout(timeout);
    };
  }, [disabled, timeoutMillis, handler]);
}
