import { AxiosResponse } from "axios";
import { useEffect } from "react";
import apiAxios from "../api/apiAxios";

interface StatusInterceptors {
  /**
   * A function to call when an HTTP 401 response is returned.
   */
  onUnauthorized(): void;
}

/**
 * Creates an Axios response interceptor that calls a functions based on http status codes.
 */
export function useAxiosStatusInterceptor({
  onUnauthorized,
}: StatusInterceptors) {
  useEffect(() => {
    const interceptorId = apiAxios.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (response: AxiosResponse) => {
        if (response.status === 401) {
          await onUnauthorized();
        }
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response;
      },
    );

    return () => {
      apiAxios.interceptors.response.eject(interceptorId);
    };
  }, [onUnauthorized]);
}
