import React, { FC } from "react";
import styled from "styled-components/macro";
import { HealthCheckResult } from "shared/lib/types/health/HeathCheckResult";
import { HealthCheckStatus } from "shared/lib/constants/health/HealthCheckStatus";
import RainbowModal, {
  RainbowModalContainer,
} from "../RainbowModal/RainbowModal";
import ShadowButton from "../ShadowButton/ShadowButton";
import { ZIndex } from "../../constants/zIndex";

interface HealthCheckModalProps {
  healthCheckResult: Pick<
    HealthCheckResult,
    "status" | "versionMismatch"
  > | null;
  onRefresh?(): void;
}

function getMessageForResult({
  versionMismatch,
  status,
}: Pick<HealthCheckResult, "status" | "versionMismatch">) {
  if (versionMismatch) {
    return (
      <>
        We had an update last night.
        <br />
        Please click the button below to refresh your browser.
      </>
    );
  }

  if (status === HealthCheckStatus.INTERRUPTED) {
    return (
      <>
        Server connection interrupted.
        <br />
        Please refresh your browser.
      </>
    );
  }

  if (status === HealthCheckStatus.INACCESSIBLE) {
    return (
      <>
        Cannot connect to the app server.
        <br />
        Please contact support.
      </>
    );
  }
  return undefined;
}

const HealthCheckModal: FC<HealthCheckModalProps> = ({
  healthCheckResult,
  onRefresh = window.location.reload,
  ...rest
}) => {
  if (!healthCheckResult?.versionMismatch) {
    return null;
  }
  return (
    <RainbowModal
      onClose={onRefresh}
      title={healthCheckResult?.versionMismatch ? "Refresh!" : "Error!"}
      {...rest}
    >
      <h3>{getMessageForResult(healthCheckResult)}</h3>
      <ShadowButton onClick={onRefresh}>Refresh</ShadowButton>
    </RainbowModal>
  );
};

export default styled(HealthCheckModal)`
  /* 
    This is a workaround for the intro.js overlays not being able to exist while this modal is present.
    This sibling selectors force the intro.js popover (and any other siblings) to appear below this modal.
   */
  ~ * {
    z-index: ${ZIndex.OVERLAY - 3};
  }
  ${RainbowModalContainer} {
    height: 288px;
  }
  h3 {
    color: #2b2c28;
    font-size: 1.5rem;
    line-height: 1.875rem;
    text-align: center;
    font-weight: normal;
  }

  ${ShadowButton} {
    height: 60px;
    width: 153px;
    border: 2px solid #2b2c28;
    border-radius: 8px;
    background-color: #ffffff;
    color: #2b2c28;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5rem;
    text-align: center;
    margin-top: 2.25rem;
  }
`;
