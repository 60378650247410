import React, { FC, ComponentPropsWithoutRef } from "react";
import styled from "styled-components/macro";

interface Props extends Omit<ComponentPropsWithoutRef<"div">, "aria-live"> {
  show?: boolean;
  liveOption?: "assertive" | "polite";
}

const AriaLive: FC<React.PropsWithChildren<Props>> = ({
  show,
  liveOption = "polite",
  children,
  ...rest
}) => (
  <div aria-live={liveOption} {...rest}>
    {show ? children : ""}
  </div>
);

export default styled(AriaLive)`
  position: absolute;
  opacity: 0;
`;
