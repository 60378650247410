import React, { FC } from "react";
import styled from "styled-components/macro";
import ScaleLoader from "react-spinners/ScaleLoader";

interface Props {
  color?: string;
  height?: number;
  width?: number;
  radius?: number;
  margin?: number;
}

const Spinner: FC<React.PropsWithChildren<Props>> = ({
  color,
  height,
  width,
  radius,
  margin,
  ...rest
}) => (
  <ScaleLoader
    color={color || "#7CAB20"}
    height={height || 20}
    width={width || 25}
    radius={radius}
    margin={margin}
    {...rest}
  />
);

export default styled(Spinner)``;
