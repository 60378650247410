import Axios, { AxiosResponse, AxiosError } from "axios";

const apiAxios = Axios.create({
  baseURL: "/api",
});

apiAxios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => Promise.reject(error.response),
);

export default apiAxios;
