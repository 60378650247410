import Column from "@emberex/components/lib/Column";
import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components/macro";
import fadeIn from "../animations/fadeIn";
import Spinner from "./Spinner";
import Overlay from "./Overlay";

const SpinnerOverlay: FC<PropsWithChildren> = props => (
  <Root {...props}>
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  </Root>
);

export default styled(SpinnerOverlay)``;

const Root = styled(Overlay)`
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const SpinnerContainer = styled(Column)`
  opacity: 0;
  animation: ${fadeIn} 0.5s both;
`;
