import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import React, { FC } from "react";
import styled, { keyframes } from "styled-components/macro";
import { RainbowBackgroundGradient } from "../RainbowGradient/RainbowGradient";
import BlankButton from "../BlankButton";
import { blackX } from "../../images";
import useFocus from "../../hooks/useFocus";
import AriaLive from "../AriaLive";
import { mobileMaxRotated } from "../../constants/breakpoints";
import Overlay from "../Overlay";
import { ZIndex } from "../../constants/zIndex";

interface RainbowModalProps {
  title: string;

  /**
   * Function called when the close button is pressed.
   * If omitted, the close button will not appear
   */
  onClose?(): void;

  show?: boolean;
}

const RainbowModal: FC<React.PropsWithChildren<RainbowModalProps>> = ({
  title,
  onClose,
  show = true,
  children,
  ...rest
}) => {
  const { focusRef, focus } = useFocus<HTMLButtonElement>(true);
  const showClose = !!onClose;
  return (
    <Root {...rest}>
      <RainbowModalContainer show={show}>
        <ModalContent>
          <CloseButtonContainer>
            {showClose && (
              <BlankButton onClick={onClose} ref={focusRef}>
                <img src={blackX} alt="close" />
              </BlankButton>
            )}
          </CloseButtonContainer>
          <h2>{title}</h2>
          {children}
          <AriaLive
            show={showClose}
            onFocus={focus}
            tabIndex={showClose ? 0 : -1}
          >
            This modal is preventing you from tabbing out of it. Press the TAB
            key to return to the Close button, then press the ENTER key to
            leave.
          </AriaLive>
        </ModalContent>
      </RainbowModalContainer>
    </Root>
  );
};

export default styled(RainbowModal)``;

const Root = styled(Overlay)`
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.9);
  overflow: hidden;
  align-items: center;
  overflow-y: auto;
  z-index: ${ZIndex.OVERLAY};
`;

const flyIn = keyframes`
  from { 
    transform: translateY(-100%);
  } 
  to { transform: translateY(0);}
`;

export const RainbowModalContainer = styled(Column)<{ show: boolean }>`
  ${RainbowBackgroundGradient}
  border-radius: 8px;
  padding: 3px;
  height: 483px;
  width: 812px;
  margin-top: 8.75rem;
  @media ${mobileMaxRotated} {
    margin-top: 2.5rem;
  }
  transition: transform 0.3s;
  transform: translateY(${props => (props.show ? 0 : -100)}%);
  animation: ${flyIn} 0.3s forwards;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);

  ${AriaLive} {
    top: 100%;
  }
`;

const ModalContent = styled(Column)`
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  align-items: center;
  h2 {
    color: #75a21d;
    font-size: 3.75rem;
    letter-spacing: 0;
    line-height: 4.5625rem;
    text-align: center;
    font-weight: 400;
  }
`;

const CloseButtonContainer = styled(Row)`
  justify-content: flex-end;
  width: 100%;
  height: 30px;
  ${BlankButton} {
    height: 30px;
    padding: 0.75rem 0.75rem 0 0.75rem;
    img {
      width: 15px;
      height: 15px;
    }
  }
`;
