import { useCallback, useEffect, useState } from "react";
import { SECOND } from "shared/lib/constants/timeConstants";
import { HealthCheckResult } from "shared/lib/types/health/HeathCheckResult";
import { HealthCheckStatus } from "shared/lib/constants/health/HealthCheckStatus";
import { useWorkerInterval } from "../useWorkerInterval";
import { checkHealth } from "../../api/health-check/checkHealth";
import { refreshVersionCookie } from "../../api/health-check/refreshVersionCookie";
import { isTest } from "../../env";

const MAX_FAILURES_BEFORE_INACCESSIBLE = 15;
const HEALTH_CHECK_INTERVAL = 10 * SECOND;

interface HealthCheckState {
  healthCheckResult: HealthCheckResult | null;
  failureCount: number;
}

export function useHealthCheck() {
  const [
    healthCheckState,
    setHealthCheckState,
  ] = useState<HealthCheckState | null>(null);

  const runHealthCheck = useCallback(async () => {
    try {
      setHealthCheckState({
        healthCheckResult: await checkHealth(),
        failureCount: 0,
      });
    } catch (e) {
      console.error(e);
      setHealthCheckState(prevState => {
        // When the client or server restarts, this gets VERY annoying in development.
        if (process.env.NODE_ENV === "development") {
          return prevState;
        }
        // Increment the failure count.
        const failureCount = (prevState?.failureCount ?? 0) + 1;
        return {
          healthCheckResult: {
            ...(prevState?.healthCheckResult ?? {}),
            // If the count exceeds the max failures, move the status to inaccessible. Otherwise, mark it as interrupted.
            status:
              failureCount < MAX_FAILURES_BEFORE_INACCESSIBLE
                ? HealthCheckStatus.INTERRUPTED
                : HealthCheckStatus.INACCESSIBLE,
          },
          failureCount,
        };
      });
    }
  }, []);

  useEffect(() => {
    if (!isTest) {
      runHealthCheck();
    }
  }, [runHealthCheck]);

  useWorkerInterval(runHealthCheck, HEALTH_CHECK_INTERVAL, isTest);

  const onRefresh = useCallback(async () => {
    if (healthCheckState?.healthCheckResult?.versionMismatch) {
      await refreshVersionCookie();
      await runHealthCheck();
    }
    window.location.reload();
  }, [healthCheckState, runHealthCheck]);

  return {
    healthCheckResult: healthCheckState?.healthCheckResult ?? null,
    onRefresh,
  };
}
