import { css } from "styled-components/macro";

const GRADIENT_STEPS: string[] = [
  "#BA612A",
  "#D19948",
  "#D7A94B",
  "#E2C752",
  "#EEE858",
  "#E2E252",
  "#D0D849",
  "#C4D244",
  "#9CBC30",
  "#80AD22",
  "#73A62A",
  "#539751",
  "#3E8D6B",
  "#2D857F",
  "#207E90",
  "#1F7E91",
];

// This generates the color stops for the linear gradient. Doing it this way allows us to
// add or remove stops without having to worry about calculating the percentages
const GRADIENT_STOPS: string = GRADIENT_STEPS.map(
  (color, index) => `${color} ${(index / GRADIENT_STEPS.length) * 100}%`,
).join(", ");

export const RainbowBackgroundGradient = css`
  background: linear-gradient(90deg, ${GRADIENT_STOPS});
`;
