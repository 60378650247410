import { useState, useEffect } from "react";
import { flushSync } from "react-dom";

export default function usePrintDetect() {
  const [printMode, setPrintMode] = useState(false);

  useEffect(() => {
    const handleBeforePrint = () => {
      /*
       * React 18 batches state updates, so if the beforeprint and afterprint fire quick enough,
       * only the final state will be picked up (false) despite this being true for an instant.
       * flushSync forces the state updates to be synchronous/unbatched
       */
      flushSync(() => setPrintMode(true));
    };
    const handleAfterPrint = () => {
      // This is a "workaround" for https://bugs.chromium.org/p/chromium/issues/detail?id=1075565
      window.requestAnimationFrame(() => {
        flushSync(() => setPrintMode(false));
      });
    };
    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);
    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  return printMode;
}
