import { useQuery } from "@tanstack/react-query";
import React, { PropsWithChildren, createContext, useContext } from "react";
import { SUPPORT_EMAIL } from "shared/lib/constants/App";
import { MINUTE } from "shared/lib/constants/timeConstants";
import { getSupportEmail } from "../api/support/getSupportEmail";

interface GlobalAppContextValues {
  supportEmail: string;
}

export const GlobalAppContext = createContext<GlobalAppContextValues>({
  supportEmail: SUPPORT_EMAIL,
});

export function useGlobalAppContext(): GlobalAppContextValues {
  return useContext(GlobalAppContext);
}

const CACHE_TIME = 30 * MINUTE;

export const GlobalAppContextProvider = ({ children }: PropsWithChildren) => {
  const { data: supportEmail = SUPPORT_EMAIL } = useQuery({
    queryFn: getSupportEmail,
    queryKey: [getSupportEmail.queryKey],
    cacheTime: CACHE_TIME,
    staleTime: CACHE_TIME,
  });

  return (
    <GlobalAppContext.Provider value={{ supportEmail }}>
      {children}
    </GlobalAppContext.Provider>
  );
};
