export enum ZIndex {
  FOOTER = 150,
  /**
   * z-index for an overlay that doesn't allow any interaction under the overlay.
   */
  OVERLAY = 9999,

  /**
   * z-index value for an <Alert /> or similar
   */
  ALERT,
}
