import { AdminSession, TeacherSession } from "shared/lib/interfaces/Session";
import apiAxios from "../apiAxios";

export interface GetSessionResponse {
  session: AdminSession | TeacherSession | null;
  expiresAt: number | null;
}

export default async function getSession() {
  const result = await apiAxios.get<GetSessionResponse>("/auth/session");
  return result.data;
}

getSession.queryKey = "auth.getSession";
