import styled from "styled-components/macro";
import BlankButton from "../BlankButton";

/**
 * A bordered button with a shadow that disappears upon click
 */
export default styled(BlankButton)`
  height: 60px;
  width: 96px;
  border: 2px solid #ffffff;
  border-radius: 8px;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s;
  &:active,
  &:disabled {
    box-shadow: none;
  }
`;
